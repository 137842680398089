import { Injectable, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ECallScriptForms, ECallScriptRoutes, ECallScriptSections, ECallScriptTypes } from 'src/app/Shared/enum';
import { CallScriptService } from '../call-script.service';
import { UserSessionService } from '../usersession.service';
import { ICallScriptModel, IRouteDefinedModel } from 'src/app/Shared/interface';

@Injectable({
  providedIn: 'root'
})
export class FacOpsDataService {

  constructor(private callScriptService: CallScriptService,
    private userSessionService: UserSessionService) { }

  militaryStateList = [];
  contactInfo;
  processingUnit;
  marketingInfo;
  unitList = [];
  cartItems = [];
  selectedUnits;
  changedUnit;
  locationUnitList = [];
  storeList = [];
  facilityReservationSettingList = [];
  inquiryList = [];
  reservationList = [];
  rentalList = [];
  dispositionId;
  infoSheet;
  invoiceSettings;
  taxExemptInfo;
  militaryInfo;
  BusinessAccInfo;
  alternateContactInfo = [];
  totalMoveInCost;
  demographyInfo;
  allowanceList;
  allProspectList = [];
  InquirySource;
  InquirySourceName;
  saveautopay;
  demographyOfOldUnit;
  Transferautopay
  autoPayData;
  processingUnitId;
  processingUnitStatus;
  lovStatusList = [];
  categoryList = [];
  attributeList = [];
  ProspectItemType: string;
  confirmationNumberForMessege: number;
  LeaseNumber: number;
  reservationNumber: number;
  inquiryNumber: number;

  contactInfoFormFields;
  contactInfoBuissnessFromFields;
  marketingFormFields;
  tempScreenpopupDetails;

  tenatBlackListed = false;
  tempDayEndDetails;
  selectedContactCountryId;
  prospectsComponentLoaded = false;


  quickStartCurrentFacilityDateDefault = new Date();
  quickStartUnitInfoBasicDetails;
  CallCenterAddSourceFacility;

  @Output() emitUnitStore = new EventEmitter();
  @Output() emitAdSourceFacility = new EventEmitter();
  @Output() emitScreenPopupDetails = new EventEmitter();
  @Output() emitTenantBlackListed = new EventEmitter();

  autoSaveInquiry: EventEmitter<any> = new EventEmitter();
  autoSaveUnitDetails;
  tempUnitInfoDetails;

  /////////////// Call Script: To maintain separate observables for each component ////////////////////

  @Output() emitAltContactCallScriptOpen = new EventEmitter();
  @Output() emitAltContactCallScriptClose = new EventEmitter();
  routeDefinedResponse = null;
  onPageOpen = false;
  isFromFollowup = false;
  isFromScreenPopup = false;
  callerScriptParameters = {
    Type: 0,
    Route: 0
  };
  private scriptData: any[] = [];
  private scriptIndices = new Map<number, number>();
  private currentType: number;
  private scriptIndexSource = new BehaviorSubject<{ section: number, index: number }>({ section: 0, index: 0 });
  scriptIndex$ = this.scriptIndexSource.asObservable();
  resetToExistingData = false;
  isSMSUnchecked = false;
  setScriptData(scriptType: number, data: any) {
    let scriptInfo = data.CallScriptForm.sort((a, b) => a.Order - b.Order);
    if (this.resetToExistingData) {
      scriptInfo.map((ele) => {
        ele['firstTimeCalled'] = true;
        ele['buttonNavigate'] = false;
      });
      this.resetToExistingData = false;
    } else {
      scriptInfo.map((ele) => {
        ele['firstTimeCalled'] = false;
        ele['buttonNavigate'] = false;
      });
    }
    this.scriptData[scriptType] = scriptInfo;
    this.onPageOpen = false;
    this.setIsNavigateFlag(scriptType, false);
    this.scriptIndexSource.next({ section: scriptType, index: 0 });
  }

  getScript(scriptType: number) {
    const scriptInfo = this.scriptIndexSource.getValue();
    if (scriptType !== scriptInfo.section) {
      let indexcript = this.getScriptIndexForComponent(scriptType);
      return this.scriptData[scriptType] && this.scriptData[scriptType].length > indexcript
        ? this.scriptData[scriptType][indexcript]
        : null;
    } else {
      return this.scriptData[scriptInfo.section] && this.scriptData[scriptInfo.section].length > scriptInfo.index
        ? this.scriptData[scriptInfo.section][scriptInfo.index]
        : null;
    }
  }

  getScriptIndexForComponent(componentType: number): number {
    return this.scriptIndices.get(componentType) || 0;
  }

  nextScript() {
    let scriptInfo = this.scriptIndexSource.getValue();
    if (this.scriptData[scriptInfo.section] && scriptInfo.index < this.scriptData[scriptInfo.section].length - 1) {
      scriptInfo.index++;
      this.setIsNavigateFlag(scriptInfo.section, true);
      this.onPageOpen = false;
      this.scriptIndexSource.next(scriptInfo);
      this.incrementScriptIndex(scriptInfo.section);
    }
  }

  previousScript() {
    const scriptInfo = this.scriptIndexSource.getValue();
    if (this.scriptData[scriptInfo.section] && scriptInfo.index > 0) {
      scriptInfo.index--;
      this.setIsNavigateFlag(scriptInfo.section, true);
      this.onPageOpen = false;
      this.scriptIndexSource.next(scriptInfo);
      this.decrementScriptIndex(scriptInfo.section);
    }
  }

  setIsNavigateFlag(section, value) {
    let script = this.getScript(section);
    if (script) {
      script.buttonNavigate = value;
    }
  }

  isFirstScript() {
    const scriptInfo = this.scriptIndexSource.getValue();
    if (scriptInfo.index == 0 || !this.scriptData[scriptInfo.section].length) {
      return true;
    }
    return false;
  }

  isLastScript() {
    const scriptInfo = this.scriptIndexSource.getValue();
    if (!this.scriptData[scriptInfo.section] || !this.scriptData[scriptInfo.section].length ||
      scriptInfo.index >= this.scriptData[scriptInfo.section].length - 1) {
      return true;
    }
    return false;
  }

  setCurrentType(type: number, resetScriptInfo): void {
    this.currentType = type;
    let currentScriptIndex = 0;
    if (resetScriptInfo) {
      currentScriptIndex = 0;
    } else {
      currentScriptIndex = this.getScriptIndexForComponent(type);
    }
    this.onPageOpen = false;
    this.setIsNavigateFlag(type, false);
    this.scriptIndexSource.next({ section: type, index: currentScriptIndex });
  }

  getCurrentType(): number {
    return this.currentType;
  }

  incrementScriptIndex(componentType: number): void {
    let currentIndex = this.getScriptIndexForComponent(componentType);
    this.scriptIndices.set(componentType, ++currentIndex);
  }

  decrementScriptIndex(componentType: number): void {
    let currentIndex = this.getScriptIndexForComponent(componentType);
    if (currentIndex > 0) {
      this.scriptIndices.set(componentType, --currentIndex);
    }
  }

  resetToExisting() {
    this.clearCallScript();
    this.resetToExistingData = true;
    this.isScriptDataPresent(ECallScriptSections.Prospect, false, false, false);
    this.setCurrentType(ECallScriptSections.Prospect, true);

  }

  isScriptDataPresent(section, onChangeRoute, isDripCampain, isScreenPopup) {
    if (!this.scriptData[section] || !this.scriptData[section].length || onChangeRoute) {
      let facilityId = this.userSessionService.isCallcenterAgent ? 0 : this.userSessionService.userSessionData.LastAccessedStoreId;
      if ((section == ECallScriptSections.Prospect && !this.isFromFollowup && !this.isFromScreenPopup) || (isScreenPopup && section == ECallScriptSections.MatchingContact) || section == ECallScriptSections.FollowUp) {
        let routeData = this.getRouteDefinedData(this.callerScriptParameters.Type, section);
        if (routeData) {
          this.routeDefinedResponse = routeData;
          if (this.callerScriptParameters.Route == ECallScriptRoutes.Inbound) {
            if (!this.routeDefinedResponse.InBound) {
              if (this.routeDefinedResponse.OutBound) {
                this.callerScriptParameters.Route = ECallScriptRoutes.Outbound;
              } else {
                this.callerScriptParameters.Route = 0;
              }
            }
          } else if (this.callerScriptParameters.Route == ECallScriptRoutes.Outbound) {
            if (!this.routeDefinedResponse.OutBound) {
              if (this.routeDefinedResponse.InBound) {
                this.callerScriptParameters.Route = ECallScriptRoutes.Inbound;
              } else {
                this.callerScriptParameters.Route = 0;
              }
            }
          }
          if (this.callerScriptParameters.Route !== 0) {
            this.getCallScriptForSection(section, isDripCampain, facilityId);
          }
        }
      } else if (this.callerScriptParameters.Route !== 0) {
        this.getCallScriptForSection(section, isDripCampain, facilityId);
      }
    }
  }

  getCallScriptForSection(section, isDripCampain, facilityId) {
    let scriptForSetion = this.getScriptForInput(section, facilityId);
    if (scriptForSetion) {
      if (isDripCampain) {
        scriptForSetion.CallScriptForm = scriptForSetion.CallScriptForm.filter((ele) => ele.FormId !== ECallScriptForms['Prospect/Reservation/Rental: Add Follow Up'])
      } else {
        scriptForSetion.CallScriptForm = scriptForSetion.CallScriptForm.filter((ele) => ele.FormId !== ECallScriptForms['Prospect/Reservation/Rental: Edit Follow Up'])
      }
      this.setScriptData(section, scriptForSetion);
    }
  }

  checkIfRedirect(scriptDetails, currentComp, isSameTabActive) {
    if (!scriptDetails || !scriptDetails.FormId || (scriptDetails.FormId == currentComp && isSameTabActive)) {
      return false;
    } else {
      if (currentComp == ECallScriptForms['Prospects: New : Contact Info'] &&
        (scriptDetails.FormId == ECallScriptForms['Prospects: New : Contact Info'] ||
          scriptDetails.FormId == ECallScriptForms['Prospects: New: Contact Info: Business Account']) && isSameTabActive) {
        return false;
      } else if (currentComp == ECallScriptForms['Prospects: New : Marketing'] &&
        scriptDetails.FormId == ECallScriptForms['Prospects: New : Marketing'] && isSameTabActive) {
        return false;
      } else if (currentComp == ECallScriptForms['Prospects: Unit Info'] &&
        (scriptDetails.FormId == ECallScriptForms['Prospects: Unit Info'] ||
          scriptDetails.FormId == ECallScriptForms['Prospects: New : Unit Info : Add Unit']) && isSameTabActive) {
        return false;
      }
      return true;
    }
  }

  getScriptForInput(section, facilityId) {
    let scriptForSetion = null;
    let allScriptData = this.userSessionService.userSessionData.AllCallScriptData;
    scriptForSetion = allScriptData.find((ele) => ele.Route == this.callerScriptParameters.Route && ele.Type == this.callerScriptParameters.Type && ele.Section == section)
    return scriptForSetion;

  }

  getRouteDefinedData(type, section) {
    let routeData = {
      InBound: false,
      OutBound: false
    }
    let allScriptData = this.userSessionService.userSessionData.AllCallScriptData.filter((ele) => ele.Type == type && ele.Section == section);
    routeData.InBound = allScriptData.find((ele) => ele.Route == ECallScriptRoutes.Inbound) ? true : false;
    routeData.OutBound = allScriptData.find((ele) => ele.Route == ECallScriptRoutes.Outbound) ? true : false;
    return routeData;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////


  setUnitStatus(obj) {
    if (this.unitList && this.unitList.find(o => o.UnitId === obj.UnitId) === undefined) {
      var index = this.unitList.findIndex(o => o.UnitId === this.processingUnit.UnitId);
      if (index !== -1) {
        this.changedUnit.BillingCycleId = obj.BillingCycleId ? obj.BillingCycleId : this.changedUnit.BillingCycleId;
        this.changedUnit.ProcessType = obj.Type;
        this.unitList[index] = this.changedUnit;
      }
    } else {
      let unitObj1 = this.unitList.filter(o => o.UnitId === obj.UnitId && o.StatusDescription !== 'Rented');
      unitObj1 = unitObj1.filter((ele) => ele.StatusDescription !== 'Expired' && ele.StatusDescription !== 'Closed' && ele.StatusDescription !== 'Cancelled')
      if (unitObj1.length) {
        unitObj1.map((item) => {
          if (item.ConfirmationNumber == obj.ConfirmNum || item.ConfirmationNumber == 0) {
            item.BillingCycleId = obj.BillingCycleId ? obj.BillingCycleId : item.BillingCycleId;
            item.ProcessType = obj.Type;
            item.ConfirmationNumber = obj.ConfirmNum;
            item.DateRequired = obj.DateRequired;
          }
        })
      }
    }
    if (this.cartItems && this.cartItems.length) {
      var pos = this.cartItems.findIndex(o => o.UnitId === obj.UnitId);
      this.cartItems.splice(pos, 1);
    }
  }

  clearCallScript() {
    this.isFromFollowup = false;
    this.isFromScreenPopup = false;
    this.scriptData = [];
    this.currentType = 0;
    this.scriptIndices.clear();
    this.onPageOpen = false;
    this.scriptIndexSource.next({ section: 0, index: 0 });
  }

  clearCallScriptOnRouteChange() {
    this.scriptData = [];
    this.scriptIndices.clear();
    this.onPageOpen = false;
    this.scriptIndexSource.next({ section: 0, index: 0 });
  }

  clearService() {
    this.contactInfo = null;
    this.processingUnit = null;
    this.processingUnitId = null;
    this.marketingInfo = null;
    this.unitList = [];
    this.cartItems = null;
    this.selectedUnits = null;
    this.locationUnitList = [];
    this.storeList = [];
    this.facilityReservationSettingList = [];
    this.inquiryList = [];
    this.reservationList = [];
    this.rentalList = [];
    this.infoSheet = null;
    this.invoiceSettings = null;
    this.taxExemptInfo = null;
    this.militaryInfo = null;
    this.BusinessAccInfo = null;
    this.alternateContactInfo = [];
    this.demographyInfo = null;
    this.allowanceList = null;
    this.processingUnitId = null;
    this.tenatBlackListed = false;
    this.autoPayData = null;
    this.demographyOfOldUnit = null;
    this.quickStartUnitInfoBasicDetails = null;
    this.quickStartCurrentFacilityDateDefault = new Date();
  }
  
  clearSMSServices(){
    this.LeaseNumber = null;
    this.reservationNumber = null;
    this.inquiryNumber = null;
    this.confirmationNumberForMessege = null;
    this.ProspectItemType = null;
  }
}
